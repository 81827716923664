import { type BankData, bankDataByIBAN } from "bankdata-germany";
import { electronicFormatIBAN, isValidIBAN as isValidIBANGlobal } from "ibantools";

export const usePaymentModule = defineStore("payment", () => {
  // ** STATES **
  // #region states

  // #endregion
  // ** DATA **
  // #region data

  interface UnzerSepaResponse {
    id: string;
    method: string;
    recurring: boolean;
    customerId: string;
    iban: string;
    bic: string;
    holder: string;
    geoLocation: object;
  }

  const URL_UNZER_API_SEPA = "https://api.unzer.com/v1/types/sepa-direct-debit";
  // #endregion
  // ** UI/VIEW **
  // #region ui-view

  // #endregion
  // ** INTERNAL FUNCTIONS **
  // #region internal-func

  // #endregion
  // ** PUBLIC FUNCTIONS **
  // #region public-func

  function getBankData(ibanValue: string): BankData | undefined {
    const ibanFormatted = electronicFormatIBAN(ibanValue);
    // Check if the IBAN is valid (internationally)
    if (ibanFormatted !== null && isValidIBANGlobal(ibanFormatted)) {
      // For German IBANs, we can get the BIC from the IBAN
      const bankData = bankDataByIBAN(ibanFormatted);
      if (bankData) {
        return bankData;
      }
    }
  }

  /**
   * Validate the IBAN
   *
   * @param ibanValue IBAN from view
   */
  function validateIban(ibanValue: string): boolean {
    const ibanFormatted = electronicFormatIBAN(ibanValue);

    if (ibanFormatted) {
      return isValidIBANGlobal(ibanFormatted);
    }
    return false;
  }

  /**
   * Create a sepa-direct-debit payment method resource.
   */
  async function createSepaDirectDebit(iban: string) {
    const {
      public: rt_public,
    } = useRuntimeConfig();

    const str = rt_public.apiEnvironment === "production"
      ? `${rt_public.unzerApiKeyPublic}:`
      : `${rt_public.unzerApiKeyStagingPublic}:`;

    // eslint-disable-next-line node/prefer-global/buffer
    const base64key = Buffer.from(str, "utf-8").toString("base64");
    try {
      // @ts-expect-error does not result in a type error
      const unzerResponse = await $fetch<UnzerSepaResponse>(
        URL_UNZER_API_SEPA,
        {
          method: "POST",
          headers: {
            Authorization: `Basic ${base64key}`,
          },
          body: {
            iban,
          },
        },
      );

      return unzerResponse.id;
    }
    catch (error) {
      await AnybillLogger.instance.warn(
        "Could not get a correct response from Unzer services",
        error instanceof Error
          ? error
          : new Error("Could not get a correct response from Unzer services"),
      );
    }
  }
  // #endregion
  return { createSepaDirectDebit, getBankData, validateIban };
});

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(usePaymentModule, import.meta.hot));
